import { useEffect } from 'react';
import './demo.scss'

const HomeDemo = (): JSX.Element => {
  useEffect(() => {
    // googlead.xyz
    // 印尼：https://hascaring.org/pay
    // 马来：https://hascharity.org/pay
    window.open('https://play.google.com/store/apps/details?id=com.uc.minigame.relax&hl=zh', '_self')
    // window.open('https://unwfp.net/', '_self')
  })
  return (
    <>

    </>
  );
}

export default HomeDemo;